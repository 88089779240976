import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './StoreList.css';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('your-public-stripe-key'); // Add your Stripe public key


function StoreList() {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quantities, setQuantities] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      const token = process.env.REACT_APP_API_TOKEN;
      const headers = { Authorization: `Token ${token}` };

      try {
        const response = await axios.get('https://www.animpairedlove.org/catalogs/api/list/', { headers });
        if (Array.isArray(response.data)) {
          setProducts(response.data);
        } else {
          setError('Received data is not an array');
        }
      } catch (err) {
        setError('Failed to fetch products');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleQuantityChange = (productId, quantity) => {
    setQuantities({ ...quantities, [productId]: quantity });
  };

  const addToCart = (product) => {
    const quantity = quantities[product.ID] || 1;

    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item.ID === product.ID);

      if (existingItem) {
        // Update quantity if item already exists in cart
        return prevCart.map((item) =>
          item.ID === product.ID ? { ...item, quantity: item.quantity + quantity } : item
        );
      } else {
        // Add new item to the cart
        return [...prevCart, { ...product, quantity }];
      }
    });
  };

  const handleCheckout = async () => {
    const stripe = await stripePromise;
    try {
      const response = await axios.post('https://your-django-backend.com/api/create-checkout-session/', {
        cart: cart,
      });
      const sessionId = response.data.id;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  if (loading) return <p>Loading products...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="store-list-container">
      <div className="store-list">
        <h2>Our Products</h2>
        <ul className="product-list">
          {products.map((product) => (
            <li key={product.ID} className="product-item">
              <img src={product.image_src} alt={product.image_alt_text || 'Product Image'} />
              <h3>{product.title}</h3>
              <p>Price: ${product.cost_per_item}</p>
              <p>In Stock: {product.inventory_qty}</p>
              <input
                type="number"
                min="1"
                max={product.inventory_qty}
                value={quantities[product.ID] || 1}
                onChange={(e) => handleQuantityChange(product.ID, parseInt(e.target.value))}
              />
              <button onClick={() => addToCart(product)}>Add to Cart</button>
            </li>
          ))}
        </ul>
      </div>

      <div className="cart-side-panel">
        <h2>Your Cart</h2>
        {cart.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          cart.map((item, index) => (
            <div key={index} className="cart-item">
              <h4>{item.title}</h4>
              <p>Quantity: {item.quantity}</p>
              <p>Price: ${item.cost_per_item * item.quantity}</p>
            </div>
          ))
        )}
        <p>Total: ${cart.reduce((total, item) => total + item.cost_per_item * item.quantity, 0)}</p>
        <button className="buy-now-button" onClick={handleCheckout}>Buy Now</button>
      </div>
    </div>
  );
}

export default StoreList;
