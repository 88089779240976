// Cart.js

import React, { useEffect } from 'react';

const Cart = ({ cart }) => {
  useEffect(() => {
    console.log('Current cart:', cart); // Log the cart state whenever it updates
  }, [cart]);

  return (
    <div>
      <h2>Your Cart</h2>
      {cart.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        cart.map((item, index) => (
          <div key={index} className="cart-item">
            <h4>{item.title}</h4>
            <p>Quantity: {item.quantity}</p>
            <p>Price: ${item.cost_per_item * item.quantity}</p>
          </div>
        ))
      )}
      <p>Total: ${cart.reduce((total, item) => total + item.cost_per_item * item.quantity, 0)}</p>
    </div>
  );
};

export default Cart;


