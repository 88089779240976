// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-list-container {
    display: flex;
  }
  
  .store-list {
    width: 70%;
    padding: 20px;
  }
  
  .cart-side-panel {
    width: 30%;
    padding: 20px;
    border-left: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  
  .cart-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .cart-item h4 {
    margin: 0;
  }
  
  .product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    list-style: none;
    padding: 0;
  }
  
  .product-item {
    border: 1px solid #ddd;
    padding: 16px;
    width: 200px;
    text-align: center;
  }
  
  .product-item img {
    width: 100%;
    height: auto;
  }

  /* StoreList.css */

.buy-now-button {
    background-color: #007bff; /* Blue background color */
    color: #ffffff; /* White text */
    font-size: 18px; /* Larger font size */
    padding: 12px 24px; /* Extra padding to make the button bigger */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
  }
  
  .buy-now-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  `, "",{"version":3,"sources":["webpack://./src/StoreList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,UAAU;IACV,aAAa;EACf;;EAEA;IACE,UAAU;IACV,aAAa;IACb,2BAA2B;IAC3B,yBAAyB;EAC3B;;EAEA;IACE,6BAA6B;IAC7B,eAAe;EACjB;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,aAAa;IACb,eAAe;IACf,SAAS;IACT,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA,kBAAkB;;AAEpB;IACI,yBAAyB,EAAE,0BAA0B;IACrD,cAAc,EAAE,eAAe;IAC/B,eAAe,EAAE,qBAAqB;IACtC,kBAAkB,EAAE,4CAA4C;IAChE,YAAY,EAAE,kBAAkB;IAChC,kBAAkB,EAAE,oBAAoB;IACxC,eAAe,EAAE,4BAA4B;IAC7C,sCAAsC,EAAE,+BAA+B;EACzE;;EAEA;IACE,yBAAyB,EAAE,yBAAyB;EACtD","sourcesContent":[".store-list-container {\n    display: flex;\n  }\n  \n  .store-list {\n    width: 70%;\n    padding: 20px;\n  }\n  \n  .cart-side-panel {\n    width: 30%;\n    padding: 20px;\n    border-left: 1px solid #ddd;\n    background-color: #f9f9f9;\n  }\n  \n  .cart-item {\n    border-bottom: 1px solid #ddd;\n    padding: 10px 0;\n  }\n  \n  .cart-item h4 {\n    margin: 0;\n  }\n  \n  .product-list {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px;\n    list-style: none;\n    padding: 0;\n  }\n  \n  .product-item {\n    border: 1px solid #ddd;\n    padding: 16px;\n    width: 200px;\n    text-align: center;\n  }\n  \n  .product-item img {\n    width: 100%;\n    height: auto;\n  }\n\n  /* StoreList.css */\n\n.buy-now-button {\n    background-color: #007bff; /* Blue background color */\n    color: #ffffff; /* White text */\n    font-size: 18px; /* Larger font size */\n    padding: 12px 24px; /* Extra padding to make the button bigger */\n    border: none; /* Remove border */\n    border-radius: 5px; /* Rounded corners */\n    cursor: pointer; /* Pointer cursor on hover */\n    transition: background-color 0.3s ease; /* Smooth transition on hover */\n  }\n  \n  .buy-now-button:hover {\n    background-color: #0056b3; /* Darker blue on hover */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
