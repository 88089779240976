// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commercial-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .commercial-popup-content {
    position: relative;
    width: 80%;
    max-width: 600px;
    background-color: white;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
  }
  
  .commercial-popup-content img {
    max-width: 100%;
    height: auto;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .close-btn:hover {
    background: darkred;
  }
  `, "",{"version":3,"sources":["webpack://./src/CommercialPopup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".commercial-popup-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.8);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .commercial-popup-content {\n    position: relative;\n    width: 80%;\n    max-width: 600px;\n    background-color: white;\n    padding: 20px;\n    text-align: center;\n    border-radius: 10px;\n  }\n  \n  .commercial-popup-content img {\n    max-width: 100%;\n    height: auto;\n  }\n  \n  .close-btn {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: red;\n    color: white;\n    border: none;\n    padding: 10px;\n    border-radius: 50%;\n    cursor: pointer;\n  }\n  \n  .close-btn:hover {\n    background: darkred;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
